import { SetStateAction, useEffect, useRef, useState } from "react";
import type { FormInstance } from "antd";
import { Button, Col, Form, Input, Row, List } from "antd";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import platform from "platform";
import { getUser } from "@/services/api/tasks/accountsManagementTasks";
import { sendSupportEmail } from "@/services/api/tasks/settingsTask";
import {
  showSuccessNotification,
  showErrorNotification,
} from "@/components/Notification/Notification";
import TextArea from "antd/es/input/TextArea";
import "@/assets/css/support.css";
import { appInsights } from "@/utils/AppInsight";

function SupportPage() {
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
  const [submittable, setSubmittable] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);

  const truncateFileName = (fileName: string) => {
    if (fileName.length > 5) {
      return fileName.substring(0, 10) + "...";
    }
    return fileName;
  };

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const values = Form.useWatch([], form);

    useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable}
        style={{ padding: "1.5rem", marginBottom: "25px" }}
      >
        Submit
      </Button>
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      if (file.size <= 10 * 1024 * 1024) {
        // 10MB in bytes
        if (
          file.type === "application/pdf" ||
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg"
        ) {
          if (fileList.length < 3) {
            const updatedFileList = [...fileList, file];
            setFileList(updatedFileList);
            setSelectedFile([...fileList, file]); // Update selectedFile here
          } else {
            showErrorNotification({
              title: "Error",
              message: "Can't upload more than 3 files",
              duration: 8,
            });
          }
        } else {
          showErrorNotification({
            title: "Error",
            message:
              "File type is not valid. Please upload a file type pdf or images.",
            duration: 8,
          });
        }
      } else {
        showErrorNotification({
          title: "Error",
          message:
            "File size is too large. Please upload a file less than 10MB.",
          duration: 8,
        });
      }
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [idUser, setIdUser] = useState<string>("");

  const handleRemoveFile = (index: number) => {
    const updatedFileList = [...fileList];
    updatedFileList.splice(index, 1);
    setFileList(updatedFileList);
    setSelectedFile(updatedFileList);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const createEmail = async (values: any) => {
    setSubmittable(false);
    const formData = new FormData();
    if (selectedFile) {
      selectedFile.forEach((element) => {
        formData.append(element.name, element);
      });
      // formData.append('file',selectedFile);
    }
    const description = platform.description;

    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("message", values.message);
    if (description) formData.append("clientEnviroment", description);

    sendSupportEmail?.(formData).then((response: any) => {
      if (response.status === 200) {
        showSuccessNotification({
          title: "Success",
          message: "Support Request has been sent correctly.",
        });
        form.setFieldsValue({
          message: "",
        });
        // appInsights.trackEvent({ name: "Support Request Sent" , properties: { email: values.email }});
        let listempty: SetStateAction<File[] | null> = [];
        setSelectedFile(listempty);
      } else {
        showErrorNotification({
          title: "Error",
          message: "Support Request has not been sent.",
          duration: 8,
        });
        // appInsights.trackException({ exception: new Error("Support Request has not been sent."), properties:  response });
      }
    });
    setFileList([]);
  };

  const validateEmail = (_: any, value: string) => {
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      return Promise.reject("Please enter a valid email address");
    }
    return Promise.resolve();
  };

  useEffect(() => {
    setLoading(true);
    getUser()
      .then((response) => {
        setIdUser(response.id);
        form.setFieldsValue({
          name: response.givenName + " " + response.surname,
          email: response.mail,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onFinish = (values: any) => {
    var data = {
      id: idUser,
      name: values.name,
      message: values.message,
      email: values.email,
    };
    createEmail(data);
  };

  return (
    <div className="page-content">
      <Row>
        <Col span={24}>
          <h1>Support</h1>
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ width: "100%" }}>
        <Col span={20}>
          <Form form={form} name="myForm" layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true },
                {
                  max: 50,
                  message: "Name must be 50 characters or less",
                },
              ]}
            >
              {loading ? (
                <Skeleton baseColor="#d1d1d1" height={40} />
              ) : (
                <Input placeholder="Name" />
              )}
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter your email" },
                { validator: validateEmail },
              ]}
            >
              {loading ? (
                <Skeleton baseColor="#d1d1d1" height={40} />
              ) : (
                <Input placeholder="Email" />
              )}
            </Form.Item>
            <Form.Item
              id="message"
              name="message"
              label="Message"
              style={{ paddingBottom: "15px" }}
              rules={[
                {
                  required: true,
                  message:
                    "Message must be between 15 characters and 500 characters",
                  min: 15,
                  max: 500,
                },
              ]}
            >
              {loading ? (
                <Skeleton baseColor="#d1d1d1" height={40} />
              ) : (
                <TextArea
                  style={{ height: 160 }}
                  placeholder="Message"
                  allowClear={undefined}
                />
              )}
            </Form.Item>
            <Form.Item>
              <label className="file-choose-button">
                Choose File
                <input
                  type="file"
                  accept="application/pdf, image/*"
                  onChange={handleFileChange}
                  className="file-input"
                  ref={fileInputRef}
                />
              </label>
              {fileList.length > 0 && (
                <div style={{ marginTop: "8px" }}>
                  <List
                    itemLayout="horizontal"
                    dataSource={fileList}
                    renderItem={(item, index) => (
                      <List.Item
                        actions={[
                          <Button
                            key="clear"
                            danger
                            onClick={() => handleRemoveFile(index)}
                            style={{
                              color: "white",
                              backgroundColor: "#ff4200",
                              transition: "opacity 0.3s",
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.opacity = "0.8")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.opacity = "1")
                            }
                          >
                            Clear
                          </Button>,
                        ]}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              flex: 1,
                              maxWidth: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {truncateFileName(item.name)}
                          </span>
                          <span
                            style={{ marginLeft: "8px", marginRight: "8px" }}
                          >
                            .
                          </span>
                          <span>{item.name.split(".").pop()}</span>
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
              )}
            </Form.Item>
            <Form.Item>
              <Form.Item style={{ marginTop: "1.5rem" }}>
                <SubmitButton form={form} />
              </Form.Item>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SupportPage;
