import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, FloatButton } from "antd";
import { useContext, useEffect, useState } from "react";
import "@/components/GetHelp/getHelp.css";
import { AppContext } from "@/App";
import { useLocation, useNavigate } from "react-router-dom";

export const GetHelp: React.FC = () => {
  const { missingStep, stepsCounter } = useContext(AppContext);
  const navigate = useNavigate();
  let location = useLocation();

  const [showGetHelp, setShowGetHelp] = useState<boolean>(true);

  useEffect(() => {
    if (location.pathname === "/live-video") {
      setShowGetHelp(false);
    } else {
      setShowGetHelp(true);
    }
  }, [location]);

  const goAccounts = () => {
    navigate("/accounts");
  };

  const goTutorials = () => {
    window.location.href = "https://www.flowsold.com/get-started-guide";
  };

  const goSupport = () => {
    navigate("/support");
  };

  return (
    <>
      {showGetHelp && (
        <FloatButton.Group
          trigger="click"
          className="get-help-button"
          badge={{ dot: missingStep }}
          icon={<QuestionCircleOutlined />}
          shape="circle"
        >
          <div className="get-help-buttons-groups ">
            <Button className="get-help-actions" onClick={goAccounts}>
              Setup Guide {stepsCounter}/3
            </Button>
            <Button className="get-help-actions" onClick={goTutorials}>
              Tutorials
            </Button>
            <Button className="get-help-actions" onClick={goSupport}>
              Support
            </Button>
          </div>
        </FloatButton.Group>
      )}
    </>
  );
};
